import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span style={{
      "fontWeight": "400"
    }}>
  We recently analyzed the Alexa Skill ratings for December.
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`Most Rated Skills`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  The top Skills for December based on the total number of ratings didn’t change
  too much from November, with one exception. The{" "}
    </span>
[<span style={{
      "fontWeight": "400"
    }}>Would you Rather Skill</span>
](https://www.amazon.com/Would-You-Rather-For-Family/dp/B06WGV16HR)
    <span style={{
      "fontWeight": "400"
    }}>
  {" "}
  jumped up five spots from 13th to 8th. It’s a game wherein players choose what
  they rather do – for example, would you rather skip Christmas or your birthday
  this year? They released a special holiday edition which may have contributed
  to the increase in ratings.
    </span>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06164804/most-rated.png",
        "alt": null
      }}></img></p>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`Trending Skills`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  We also looked at which Skills received the most number of new ratings during
  December. Five of the top most rated above also received the most new ratings.{" "}
    </span>
[<span style={{
      "fontWeight": "400"
    }}>Song Quiz</span>
](https://www.amazon.com/Volley-Inc-Song-Quiz/dp/B06XWGR7XZ)
    <span style={{
      "fontWeight": "400"
    }}>
  {" "}
  beat out the rest of the Skills by a significant amount with 2170 new ratings.
  It also moved up a slot in the overall most rated Skills.
    </span>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06164803/most-rated-movement.png",
        "alt": null
      }}></img></p>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`Trending Newcomers`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  There were over 1,600 new skills added during December.{" "}
    </span>
[<span style={{
      "fontWeight": "400"
    }}>Volley’s</span>](http://www.volleythat.com) [
    <span style={{
      "fontWeight": "400"
    }}>Holiday Song Quiz</span>
](https://www.amazon.com/Volley-Inc-Holiday-Song-Quiz/dp/B077SR7PFY)
    <span style={{
      "fontWeight": "400"
    }}>
  {" "}
  had the most number of ratings out of any new skills – more than double the
  next closest.
    </span>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/01/06164802/new-most-rated.png",
        "alt": null
      }}></img></p>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`Compare your Skills`}</span></h2>
    <span style={{
      "fontWeight": "400"
    }}>
  Want to see how your Skills compare to others based on the store rankings?
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  At Dashbot, we offer a competitive intelligence report for Alexa Skill
  ratings. You can check it out for free at{" "}
    </span>
[<span style={{
      "fontWeight": "400"
    }}>https://www.dashbot.io/ci/alexa</span>
](https://www.dashbot.io/ci/alexa)<span style={{
      "fontWeight": "400"
    }}>. </span>
    <span style={{
      "fontWeight": "400"
    }}>
  With our Alexa competitive intelligence report, you can compare the ratings of
  Skills over time and even save Skills to your watch list.{" "}
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`About Dashbot`}</span></h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io"
      }}><span style={{
          "fontWeight": "400"
        }}><em parentName="a">{`Dashbot`}</em></span></a>{` `}<em parentName="p">{`is a conversational analytics platform that enables brands and developers to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      